import React, {Component} from "react";
import logo from "../Images/logo.png";

class Header extends Component {
      render(){
            return (
                  <div className="bottomLogoContainer">
                        <img src={logo} alt="logo" className="bottomLogo" />
                  </div>
            )
      }
}

export default Header;