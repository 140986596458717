import React, {Component} from "react";
class Invoices extends Component {
      
      render(){
            let date = new Date(this.props.created * 1000).toString();

            return(
                  <div>
                        <p> Delivered By:{" "} <strong>{this.props.email} at {date}</strong></p>
                        
                        {this.props.customerPresent ? (
                              <p><strong>Customer was present</strong></p>
                        ) : (
                              <p><strong>Customer was not present</strong></p>
                        )}

                        <p>Notes: <strong>{this.props.notes}</strong></p>

                        <p>Signed for by: <strong>{this.props.signedForName}</strong></p>

                        {this.props.signatureUrl ? (
                              <div>
                                    <p>Attached Signature: </p>
                                    <img className="signiture" src={this.props.signatureUrl} alt="signature" />
                              </div>
                        ) : (
                              <div>
                                    <p>Attached Signature: </p>
                                    <img className="signiture" src={this.props.sigStr} alt="signature" />
                              </div>
                        )}

                        {navigator.onLine ? (
                              <div>
                              <p>Attached files: </p>
                                    <div className={"attachedFilesHolder"}>
                                          {this.props.fileList && this.props.fileList.length > 0 ? (
                                                this.props.fileList.map((file) => (
                                          <img className="podFiles" key={file} message={file} podNumber={this.props.podNumber} src={`${this.props.podUrl}`} alt="attached files not loading"/> 
                                          ))
                                          ) : (
                                          <p><strong>No Additional Files found</strong></p>
                                          )}
                                    </div>
                              </div>
                        ) : (
                        <p><strong>Additional Files not available - go online to get this data</strong></p>
                        )}
                        <br />
                  </div>
            )   
      }
}

export default Invoices;