import Firebase from "../firebase";

export const login = (email, password) => {
    return new Promise((resolve, reject) => {
        Firebase.auth
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
          resolve(user);
        })
        .catch((error) => {
          reject(error);
        });
    })
};

export const logout = () => {
    return new Promise((resolve, reject) => {
        Firebase.auth.signOut().then(() => {
            // user is signed out
            resolve();
        }).catch((error) => {
            reject(error);
        });
    });
};