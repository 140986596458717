import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBxLysOXAJUl64MeBqQ3kC7a7y76BgXmgw",
  authDomain: "vapta-pod.firebaseapp.com",
  projectId: "vapta-pod",
  storageBucket: "vapta-pod.appspot.com",
  messagingSenderId: "104086798292",
  appId: "1:104086798292:web:b14cd3cc22d5ef9dbe2041",
  measurementId: "G-MKGJ8HCC76",
};

class Firebase {
  constructor() {
    firebase.initializeApp(firebaseConfig);
    this.auth = firebase.auth();
    this.db = firebase.firestore();
    this.functions = firebase.functions();
    this.storage = firebase.storage();

    this.db.enablePersistence().catch(function (err) {
      if (err.code === "failed-precondition") {
        alert("Please close all open VAPTA POD tabs, and re load");
      } else if (err.code === "unimplemented") {
        console.log("work offline not implemented");
      }
    });
  }
}

export default new Firebase();
