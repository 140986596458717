import React from "react";
import SignIn from "./Pages/SignIn";
import ScannerPage from "./Pages/ScannerPage";
import Firebase from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import EnquiryPage from "./Pages/EnquiryPage";
import Nav from "./Components/Nav"
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import LoadingOverlay from "./Components/LoadingOverlay";

function App() {
  const [user, loading, error] = useAuthState(Firebase.auth);

  if (loading) {
      return (
            <div className="App">
                  <section><LoadingOverlay /></section>
            </div>
      )
  } 

  if (user) {
      return (
            <div className="App">
                  <section>
                        <Router>
                              <Nav />
                              <Switch>
                                    <Route exact path="/scan" component={ScannerPage} />
                                    <Route exact path="/" component={EnquiryPage} />
                              </Switch>
                        </Router >
                  </section>
            </div>
      )
  }

  if (error) {
      return (
            <div className="App">
                  <section>
                        <div>
                              <p>Error: {error}</p>
                        </div>
                  </section>
            </div>
      );
  }

  return (
      <div className="App">
      <section><SignIn /></section>
      </div>
  )

  //return (
  //  <div className="App">
   //   <section>
   //     {user ? (
  //        <Router>
   //           <Nav />
   //           <Switch>
   //             <Route exact path="/scan" component={ScannerPage} />
   //             <Route exact path="/" component={EnquiryPage} />
    //          </Switch>
  //        </Router>
  //      ) : (
   //       <SignIn />
  //      )}
   //   </section>
  //  </div>
 // );
}

export default App;