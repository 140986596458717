import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SigPad from "./SigPad";
import Header from "./Header";

import {
  NotFoundException,
  ChecksumException,
  FormatException,
  BrowserMultiFormatReader
} from "@zxing/library";

const Scanner = () => {
  const [code, setCode] = useState("");
  const [scanning, setScan] = useState(false);
  const [isActive, setLoading] = useState(false);
  const [showScanner, setShowScanner] = useState(false);

  const codeReader = new BrowserMultiFormatReader();

  codeReader
    .listVideoInputDevices()
    .then((videoInputDevices) => {
      if (videoInputDevices.length > 0) {
        setShowScanner(true);
      } else {
            // REMEMBER TO REMOVE THIS ELSE STATEMENT ONLY THERE FOR DEVELOPMENT!!!
            setShowScanner(true);
            console.log('remove this later');
      }
    })
    .catch((err) => console.error(err));

  console.log("ZXing code reader initialized");

  const handleChange = (newValue) => {
    let cap = newValue.toUpperCase();
    setCode(cap);
  };

  const handleLoader = (value) => {
    setLoading(value);
  };

  const ResetClick = () => {
    setScan(false)
  };

  const StopScan = () => {
    codeReader.reset();
    setScan(false);
    console.log("Stop.");
  };

  const StartScan = () => {
    decodeContinuously();
    handleChange("");
    setScan(true);
  };

  const decodeContinuously = () => {
    codeReader.decodeFromVideoDevice(undefined, "video", (result, err) => {
      if (result) {
        // properly decoded qr code
        console.log("Found QR code!", result);
        handleChange(result.text);
        StopScan();
      }

      if (err) {
        handleChange("");

        // As long as this error belongs into one of the following categories
        // the code reader is going to continue as excepted. Any other error
        // will stop the decoding loop.
        //
        // Excepted Exceptions:
        //
        //  - NotFoundException
        //  - ChecksumException
        //  - FormatException

        if (err instanceof NotFoundException) {
          console.log("No code found.");
        }

        if (err instanceof ChecksumException) {
          console.log("A code was found, but it's read value was not valid.");
        }

        if (err instanceof FormatException) {
          console.log("A code was found, but it was in a invalid format.");
        }
      }
    });
  };

  const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(2, 0, 1),
    },
  }));

  const classes = useStyles();



  return (
    
        <div className={classes.paper}>
          <main className="wrapper">
            {showScanner ? (
              scanning ? (
                <div>
                  {" "}

                  <button type="submit" variant="contained" className="mainSearchBtn" onClick={() => {ResetClick();}}>Stop Scan</button>
                  <label for="inp" class="inp">
                        <input id="inp" placeholder="&nbsp;" autoComplete="none" name="invoiceNoManual" onChange={(event) => handleChange(event.target.value)} value={code}></input>
                        <span class="label">Enter Invoice Number... E.g AV1234</span>
                        <span class="focus-bg"></span>
                  </label>

                </div>
              ) : (
                    
                <div>
                      
                  <button type="submit" variant="contained" className="mainSearchBtn" onClick={() => {StartScan();}}>Start scan</button>

                  <label for="inp" class="inp">
                        <input id="inp" placeholder="&nbsp;" autoComplete="none" name="invoiceNo" onChange={(event) => handleChange(event.target.value)} value={code}></input>
                        <span class="label">Enter Invoice Number... E.g AV1234</span>
                        <span class="focus-bg"></span>
                  </label>

                </div>
              )
            ) : (
                  <div>
                        <p>Sorry! You have no camera devices available. Please search package instead.</p>

                        <label for="inp" class="inp">
                              <input id="inp" placeholder="&nbsp;" autoComplete="none" name="invoiceNo" onChange={(event) => handleChange(event.target.value)}    value={code}></input>
                              <span class="label">Enter Invoice Number... E.g AV1234</span>
                              <span class="focus-bg"></span>
                        </label>
                  </div>
              
            )}

            <section className="container" id="demo-content">
              {scanning ? (
                <div>
                  <div>
                    <video id="video" width="300" height="200"/>
                  </div>
                </div>
              ) : null}

            {/* If a Invoice number has been entered load up SigPad component */}
              {code ? (
                <div>
                    <h1>Result: {code}</h1>
                  <SigPad
                    classes={classes}
                    code={code}
                    onChange={handleChange}
                    loading={handleLoader}
                  />
                </div>
              ) : null}

            </section>
          </main>
          <footer>
            <Header />
          </footer>
        </div>
    
  );
};

export default Scanner;
