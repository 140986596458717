/* eslint-disable no-loop-func */
import React from "react";
import SignaturePad from "react-signature-pad-wrapper";
import Firebase from "../firebase";
import fb from "firebase/app";
import Checkbox from "@material-ui/core/Checkbox";
import ImageUploader from "react-images-upload";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { readAndCompressImage } from "browser-image-resizer";

class SigPad extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      latitude: null,
      longitude: null,
      notes: null,
      signedName: null,
      pictures: [],
      isChecked: false,
    };
    this.onDrop = this.onDrop.bind(this);
  }

  componentDidMount() {
    this.updateGeoLocation();
  }

  updateGeoLocation() {
    window.navigator.geolocation.getCurrentPosition((success) =>
      this.setState({
        latitude: success.coords.latitude,
        longitude: success.coords.longitude,
      })
    );
  }

  handleNoteChange(event) {
    const { value } = event.currentTarget;
    this.setState({
      notes: value,
    });
  }

  handleNameChange(event) {
    const { value } = event.currentTarget;
    this.setState({
      signedName: value,
    });
  }

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: this.state.pictures.concat(pictureFiles),
    });
  }

  async handleSave(code) {
    let img = this.signaturePad.toDataURL();
    let isSigEmpty = this.signaturePad.isEmpty();
    let strImg = img.replace(/^data:image\/(png|jpg);base64,/, "");

    // if (code && code.startsWith("AV") && !isSigEmpty && img.length > 0) {
    if (code && !isSigEmpty && img.length > 0 && strImg.length > 0) {
      this.props.loading(true);

      let activeRef = await Firebase.db
        .collection(`signatures/${code}/list`)
        .get();

      if (activeRef.docs.length === 0) {
        await this.updateGeoLocation();
        this.clearPad();
        var user = Firebase.auth.currentUser;
        const uuid = this.uuidv4();
        const filename = `${code}-${uuid}`;

        let fileArrNames = [];

        this.state.pictures.forEach((img) => {
          const uuid = this.uuidv4();
          fileArrNames.push(uuid);
        });

        let success = await this.addToFirebase(
          code,
          strImg,
          user,
          filename,
          fileArrNames,
          this
        );

        if (success === true || success === false) {
          this.props.onChange("");
          this.props.loading(false);
        }
      } else {
        alert("This invoice number has already been delivered");
        this.props.loading(false);
        this.props.onChange("");
      }
    } else {
      alert(
        "Please make sure you have scanned an Advice Note barcode (check the result text) and entered a signature"
      );
    }
  }

  async addToFirebase(
    code,
    img,
    user,
    signatureFileName,
    additionalFileNames,
    thisInstance
  ) {
    let success = true;
    return new Promise(async (resolve, reject) => {
      await thisInstance
        .addToDb(code, img, user, signatureFileName, additionalFileNames)
        .then((result) => {
          if (result !== true) {
            success = false;
          }
        })
        .catch((err) => {
          success = false;
          console.error(err);
        });

      if (navigator.onLine) {
        await thisInstance
          .uploadSignature(code, img, user, signatureFileName)
          .then((result) => {
            if (result !== true) {
              success = false;
            }
          })
          .catch((err) => {
            success = false;
            console.error(err);
          });

        if (thisInstance.state.pictures.length > 0) {
          await thisInstance
            .uploadImages(
              code,
              user,
              thisInstance.state.pictures,
              additionalFileNames
            )
            .then((result) => {
              if (result !== true) {
                success = false;
              }
            })
            .catch((err) => {
              success = false;
              console.error(err);
            });
        }
      } else {
        alert("You are offline. Additional images have not been uploaded.");
      }

      if (success) {
        alert("Success! POD has been uploaded.");
        resolve(true);
      } else {
        alert("Error! POD not successful. Please try again.");
        reject(false);
      }
    });
  }

  async addToDb(code, string, user, filename, filelist) {
    return new Promise(async (resolve, reject) => {
      if (string.length > 0) {
        let obj = {
          email: user.email,
          podNumber: code,
          signature: string,
          created: fb.firestore.FieldValue.serverTimestamp(),
          lat: this.state.latitude,
          long: this.state.longitude,
          notes: this.state.notes,
          signedForName: this.state.signedName,
          customerPresent: this.state.isChecked,
        };
  
        if (navigator.onLine) {
          obj.fileList = filelist;
          obj.fileName = filename;
  
          // we wait until the upload is done before resolving
          await Firebase.db
            .collection(`signatures/${code}/list`)
            .add(obj)
            .then(async function (docRef) {
              console.log("Document written with ID: ", docRef.id);
              resolve(true);
            })
            .catch((err) => {
              console.error(err);
              reject(false);
            });
        } else {
          // the upload will be done when we come back online
          Firebase.db
            .collection(`signatures/${code}/list`)
            .add(obj)
            .catch((err) => {
              console.error(err);
            });
          resolve(true);
        }
      } else {
        reject(false);
      }
      
    });
  }

  async uploadSignature(code, string, user, filename) {
    return new Promise(async (resolve, reject) => {
      if (user) {
        var storageRef = Firebase.storage.ref(
          `${code}/signatures/${filename}.png`
        );
        var message = "data:image/png;base64," + string;
        await storageRef
          .putString(message, "data_url")
          .then(function (snapshot) {
            console.log("Uploaded a data_url string!");
            resolve(true);
          });
      } else {
        reject(false);
      }
    });
  }

  calculateAspectRatioFit(maxWidth, maxHeight, image) {
    var reader = new FileReader();
    var height = null, width = null;

    //Read the contents of Image File.
    reader.readAsDataURL(image);
    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      //Validate the File Height and Width.
      image.onload = function () {
        height = this.height;
        width = this.width;
      };
    };

    var ratio = Math.min(maxWidth / width, maxHeight / height);

    return { width: width * ratio, height: height * ratio };
  }

  async uploadImages(code, user, pictures, fileNames) {
    return new Promise(async (resolve, reject) => {
      if (user) {
        let success = true;
        if (Array.isArray(pictures) && pictures.length > 0) {
          let nameRef = 0;
          for (let thisPic of pictures) {

            const thisAspect = this.calculateAspectRatioFit(
              720,
              720,
              thisPic
            );

            const config = {
              quality: 0.5,
              maxWidth: thisAspect.width,
              maxHeight: thisAspect.height,
              autoRotate: true,
              debug: false,
            };

            await readAndCompressImage(thisPic, config).then(
              async (resizedImage) => {
                let filename = `${code}-${fileNames[nameRef]}`;
                var storageRef = Firebase.storage.ref(
                  `${code}/images/${filename}.png`
                );
                await storageRef
                  .put(resizedImage)
                  .then(function (snapshot) {
                    console.log("Uploaded an image!");
                  })
                  .catch((err) => {
                    success = false;
                    console.error(err);
                  });
                nameRef++;
              }
            );
          }
          if (success === true) {
            resolve(true);
          } else {
            resolve(false);
          }
        } else {
          resolve(true);
        }
      } else {
        reject(false);
      }
    });
  }

  clearPad() {
    this.signaturePad.clear();
  }

  uuidv4() {
    var d = new Date().getTime(); //Timestamp
    var d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
          //Use timestamp until depleted
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          //Use microseconds since page-load if supported
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
  }

  render() {

    return (
      <div>
        <div className="border">
          <SignaturePad
            options={{
              backgroundColor: "rgba(255, 255, 255, 1)",
            }}
            redrawOnResize={true}
            ref={(ref) => (this.signaturePad = ref)}
          />
        </div>

        <button type="submit" variant="contained" className="mainSearchBtn MT" onClick={() => {this.clearPad();}}>Clear signature</button>

            <label for="inp" class="inp">
                  <input id="inp" placeholder="&nbsp;" name="name" onChange={(event) => this.handleNameChange(event)} ></input>
                  <span class="label">Name</span>
                  <span class="focus-bg"></span>
            </label>

            <label for="inp" class="inp">
                  <input id="inp" placeholder="&nbsp;" name="notes" onChange={(event) => this.handleNoteChange(event)} ></input>
                  <span class="label">Notes</span>
                  <span class="focus-bg"></span>
            </label>

        <FormControlLabel
          control={
            <Checkbox
              color={"primary"}
              value="checkedA"
              checked={this.state.isChecked}
              inputProps={{ "aria-label": "Checkbox A" }}
              onChange={(e) => {
                this.setState({ isChecked: e.target.checked });
              }}
            />
          }
          label="Is customer present?"
        />

        <ImageUploader
          withIcon={true}
          onChange={this.onDrop}
          imgExtension={[".jpg", ".jpeg", ".png", ".gif"]}
          maxFileSize={5242880}
          withPreview={true}
          inputRef={(iu) => (this.imageUploader = iu)}
        />

        <button type="submit" variant="contained" className="mainSearchBtn" onClick={() => {this.handleSave(this.props.code);}}>Submit POD</button>
      </div>
    );
  }
}

export default SigPad;
