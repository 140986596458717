import React, {Component} from 'react'

class LoadingOverlay extends Component {

      render(){
            return (
                  <div class="loaderContainer">
                        <div class="loader"></div>
                  </div>
            )
      }
}

export default LoadingOverlay