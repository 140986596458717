import React, { useState } from "react";
import Firebase from "../firebase";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import "../App.css";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Header from "../Components/Header";
import Invoices from "../Components/Invoice";

function EnquiryPage() {

  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [docs, setDocs] = useState([]);
  
// Gets Invoice number and uppercases it
  const handleInput = (number) => {
    let cap = number.toUpperCase();
    setInvoiceNumber(cap);
  };

  const Search = async () => {
    if (invoiceNumber) {
      var signaturesRef = Firebase.db
        .collection(`signatures/${invoiceNumber}/list`)
        .orderBy("created");

      const ref = await signaturesRef.get();
      setDocs(ref.docs);
    } else {
      setDocs([]);
    }
  };

  return (
      <CssBaseline>
      <div className="container">

            <label for="inp" class="inp">
                  <input id="inp" placeholder="&nbsp;" autoComplete="none" name="invoiceNo" onChange={(event) => handleInput(event.target.value)}></input>
                  <span class="label">Enter Invoice Number... E.g AV1234</span>
                  <span class="focus-bg"></span>
            </label>

        <button type="submit" variant="contained" className="mainSearchBtn" onClick={() => {Search();}}>Search</button>
           
        {docs && docs.length > 0 ? (
          docs.map((doc) => <Invoice key={doc.id} message={doc.data()} />)
        ) : (
          <p>No Invoices found</p>
        )}

        <footer>
          <Header />
        </footer>
        
      </div>
        
      </CssBaseline>
  );
}

// Once an invoice number has been entered this function runs which displays the invoice component 
function Invoice(props) {
      const [signatureUrl, setUrl] = useState("");
      const [podUrl, setPodUrl] = useState("");
      const {
            lat,
            long,
            fileName,
            podNumber,
            fileList,
      } = props.message;

  const mapStyles = {
    height: "25vh",
    width: "100%",
  };

  const defaultCenter = {
    lat: lat,
    lng: long,
  } 

  // Gets signature reference from database and sets the state as podUrl
  if (navigator.onLine) {
      const signatureFile = Firebase.storage.ref(
            `${podNumber}/signatures/${fileName}.png`
      );
      signatureFile.getDownloadURL().then(function (url) {
            setUrl(url);
      }).catch(err => console.log(err))
  }
  
  // Gets attached files reference from database and sets the state as podUrl
  if (navigator.onLine) {
    const podFile = Firebase.storage.ref(
      `${podNumber}/images/${podNumber}-${fileList}.png`
    );
    podFile.getDownloadURL().then(function (podUrl) {
      setPodUrl(podUrl);
    }).catch(err => console.log(err))
  }

  return (
        
    <div className={"invoice"}>
            <Invoices 
                  lat={props.message.lat}
                  long={props.message.long}
                  notes={props.message.notes}
                  fileName={props.message.fileName}
                  email={props.message.email}
                  created={props.message.created}
                  podNumber={props.message.podNumber}
                  podMessage={props.message.file}
                  podUrl={podUrl}
                  signedForName={props.message.signedForName}
                  fileList={props.message.fileList}
                  customerPresent={props.message.customerPresent}
                  signature={props.message.signature}
                  signatureUrl={signatureUrl}
                  defaultCenter={props.defaultCenter}
            />

      {defaultCenter.lat && defaultCenter.lng && navigator.onLine ? (
        <div>
          <LoadScript googleMapsApiKey="AIzaSyB6K4AZ-cHzBzwgrylmaOYOxMwjqm_Uy2w">
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={18}
              center={defaultCenter}
            >
              <Marker key={"Location"} position={defaultCenter} />
            </GoogleMap>
          </LoadScript>

          <p>If your map says 'Loading...', try refreshing the page</p>
        </div>
      ) : (
        defaultCenter.lat && defaultCenter.lng ? (
          <div>
            <p>Delivery Latitude: <strong>{defaultCenter.lat}</strong></p>
            <p>Delivery Longitude: <strong>{defaultCenter.lng}</strong></p>
          </div>
        ) : (
          <div>
            <p>Location data not available</p>
          </div>
        )
      )}
      <br />
    </div>
  );
}

export default EnquiryPage;