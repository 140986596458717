import React, {Component} from "react";
import {logout} from "../Auth/auth";

// Images import
import box from "../Images/box.png";
import location from "../Images/placeholder.png";
import camera from "../Images/camera.png";
import flash from "../Images/star.png";
import userImg from "../Images/user.png";
import arrow from "../Images/right-arrow.png";

class Nav extends Component {
      render(){
            return (
                  <nav>
                  <a href="/scan" className="btn-track">
                        <div className="--icon">
                              <div className="circle-inner"></div>
                              <div className="circle-outer"></div>
                              <img className="camera" src={camera} />
                              <img className="flash" src={flash} />
                        </div>
                        <div className="--text">Scan package</div>
                  </a>

                  <a href="/" className="btn-track">
                      <div className="--icon">
                        <div className="circle-inner"></div>
                        <div className="circle-outer"></div>
                        <img className="parcel" src={box} />
                        <img className="locationPin" src={location} />
                      </div>
                      <div className="--text">Search package</div>
                  </a>
                  
                  <a onClick={() => logout()} href="#" className="btn-track">
                      <div className="--icon">
                        <div className="circle-inner"></div>
                        <div className="circle-outer"></div>
                        <img className="userImg" src={userImg} />
                        <img className="arrow" src={arrow} />
                      </div>
                      <div className="--text">Sign-out</div>
                  </a>
              </nav>
            )
      }
}

export default Nav;