import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
// import Firebase from "../firebase";
import Header from '../Components/Header';
import {login} from '../Auth/auth';


const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault();
    login(email, password).then((user) => {
      // user is signed in
    })
    .catch((error) => {
        setError("Error signing in with password and email!");
        console.error("Error signing in with password and email", error);
    });
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline>
        <Header />
          
        <div>
          <h1 className="signInHeader">Sign in</h1>
          <form >

            <label for="inp" class="inp">
                  <input type="email" id="inp" placeholder="&nbsp;" autoComplete="email" name="userEmail" onChange={(event) => onChangeHandler(event)}></input>
                  <span class="label">Email Address</span>
                  <span class="focus-bg"></span>
            </label>

            <label for="inp" class="inp">
                  <input type="password" id="inp" placeholder="&nbsp;" autoComplete="current-password" name="userPassword" onChange={(event) => onChangeHandler(event)}></input>
                  <span class="label">Password</span>
                  <span class="focus-bg"></span>
            </label>

            <button type="submit" variant="contained" className="signInBtn" onClick={(event) => {signInWithEmailAndPasswordHandler(event, email, password)}}>Sign In</button>
          </form>
          {error !== null && (
            <h1 className="errorMessage">{error}</h1>
          )}
        </div>
      </CssBaseline>
    </Container>
  );
};
export default SignIn;
